/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Divider, Grid, Snackbar, Typography } from "@material-ui/core";
import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import { useDropzone } from "react-dropzone";
import MuiAlert from "@material-ui/lab/Alert";

export default function ClientLoadTransport(props) {
  const { load } = props;
  console.log({ load });

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  const snackbarShow = (message, severity = "info", duration = 6000) => {
    // type Color = "info" | "error" | "success" | "warning"
    setSnackbar({
      open: true,
      severity,
      message,
      duration,
    });
  };

  const snackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleReject7501 = (file, errors) => {
    snackbarShow(file.path + " is not valid. " + errors[0].message, "error");
  };

  const handleAdd7501 = (file) => {
    // let formData = new FormData();
    // formData.append("file", file);
    // fetch(`${getEnvironmentURL()}/api/reports/management/vehicleMassUpload`, {
    //   // Your POST endpoint
    //   method: "POST",
    //   headers: { ...authHeader() },
    //   body: formData,
    // })
    //   .then(
    //     (response) => response.json() // if the response is a JSON object
    //   )
    //   .then((data) => {
    //     // console.log("success", success); // Handle the success response object
    //     if (Array.isArray(data)) {
    //       this.setState({ data });
    //       this._showMessage("Data uploaded");
    //     } else {
    //       this._showMessage("Unexpected data", "error");
    //     }
    //   })
    //   .catch((error) => {
    //     this._showMessage(error, "error"); // Handle the error response object
    //   });
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => snackbarShow("Test Message", "success")}
      >
        Test Snackbar
      </Button>
      <Grid container style={{ padding: 10 }} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">7501 Not Stamped</Typography>
          <DragAndDrop
            onAddFile={handleAdd7501}
            onRejectFile={handleReject7501}
            acceptableTypes={[".pdf"]}
          >
            <Typography>Upload 7501 (not stamped)</Typography>
          </DragAndDrop>
        </Grid>
        <Grid xs={12}>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Vehicle Documents</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography style={{ fontWeight: "bold" }}>Vehicle Info</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography style={{ fontWeight: "bold" }}>Bay Docs</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography style={{ fontWeight: "bold" }}>Ownerships</Typography>
        </Grid>
        {load?.vehicles?.map((v) => (
          <Fragment key={v.vehicleid}>
            <Grid item xs={12} md={4}>
              {v.vehiclevin}
              <br />
              {v.yr} {v.make} {v.model}
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <ul> */}
              <li>Pro Forma</li>
              <li>7501 (non stamped)</li>
              <li>HS-7 & EPA</li>
              {/* </ul> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <DragAndDrop
                onAddFile={handleAdd7501}
                onRejectFile={handleReject7501}
                acceptableTypes={[".pdf"]}
              >
                <Typography>Upload Ownership for {v.vehiclevin}</Typography>
              </DragAndDrop>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={snackbarClose}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

function DragAndDrop(props) {
  const acceptableTypes = props.acceptableTypes || [".csv"];
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      if (props.metaData) {
        props.onAddFile && props.onAddFile(file, props.metadata);
      } else {
        props.onAddFile && props.onAddFile(file);
      }
    });
    fileRejections.forEach((file) => {
      if (props.metaData) {
        props.onRejectFile &&
          props.onRejectFile(file.file, file.errors, props.metaData);
      } else {
        props.onRejectFile && props.onRejectFile(file.file, file.errors);
      }
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: acceptableTypes,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {props.children || "Upload File"}
        <Divider style={{ width: "100%" }} />
        Valid Files:
        {acceptableTypes.map((acceptableType) => (
          <Typography>{acceptableType}</Typography>
        ))}
      </div>
    </div>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
